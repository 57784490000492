<template>
  <div class="card w-100" style="height: 100vh">
    <div
      class="w-100 h-100 d-flex flex-column align-items-center justify-content-center"
    >
      <b-img
        v-if="skin == 'light'"
        style="max-width: 140px"
        src="@/assets/images/logo/tprm_light.png"
        alt="logo"
      />
      <b-img
        v-else
        style="max-width: 140px"
        src="@/assets/images/logo/tprm_dark.png"
        alt="logo"
      />
      <p class="mt-1 text-center">
        Verifying your credentials. Please wait while we authenticate your
        account
      </p>
      <b-spinner variant="primary" class="mt-25" />
    </div>
  </div>
</template>

<script>
import {
  BSpinner,
  BImg,
  BRow,
  BCol,
  BButton,
  BCard,
  BFormGroup,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BModal,
  BTabs,
  BTab,
  BAvatar,
} from "bootstrap-vue";
import vSelect from "vue-select";
import useAppConfig from "@core/app-config/useAppConfig";

export default {
  components: {
    BSpinner,
    BImg,
    BRow,
    BCol,
    BButton,
    BCard,
    BFormGroup,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    BModal,
    BTabs,
    BTab,
    BAvatar,
    vSelect,
  },

  data() {
    return {
      selectedCid: null,
    };
  },

  watch: {
    selectedCid(newValue) {
      if (newValue && newValue != null) {
        this.$store.commit("app/setTenantid", newValue);
        this.handleTenantSelected();
      }
    },
  },

  computed: {
    workspaces() {
      return this.$store.state.app.workspaces;
    },
  },

  mounted() {},

  setup(props) {
    const { skin } = useAppConfig();

    return { skin };
  },

  methods: {
    logout: function () {
      this.$store.dispatch("app/logout").then(() => {
        this.$router.push("/login");
      });
    },
  },
};
</script>
